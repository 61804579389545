<template>
  <div>
    <my-nav-bar
      title="签单收款"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="unpaidReceive in list" :key="unpaidReceive.id" class="cell">
        <van-cell title="客户名称" :value="unpaidReceive.client.name" />
        <van-cell value-class="cell-amount" title="收款金额" :value="'￥' + unpaidReceive.receive_amount" />
        <van-cell is-link title="查看明细" center @click="toDetailView(unpaidReceive)" />
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getUnpaidReceives } from '@/api/statistics'
export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      listQuery: {
        time: this.$route.query.time,
        type: this.$route.query.type,
        store_id: this.$route.query.store_id
      },
      showEmpty: false
    }
  },
  created() {
    getUnpaidReceives(this.listQuery).then(res => {
      this.list = res.data
      this.showEmpty = this.list.length === 0
    })
  },
  methods: {
    toDetailView(unpaidReceive) {
      this.$router.push({
        path: '/total-data-unpaid-receive-detail',
        query: {
          ...this.listQuery,
          client_id: unpaidReceive.client_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .cell {
    padding: 0 10px 10px 10px;
  }
  .cell:first-child {
    padding-top: 10px;
  }
  .cell-amount {
    color: $amountRedColor;
  }
</style>
