var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "签单收款",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (unpaidReceive) {
    return _c('div', {
      key: unpaidReceive.id,
      staticClass: "cell"
    }, [_c('van-cell', {
      attrs: {
        "title": "客户名称",
        "value": unpaidReceive.client.name
      }
    }), _c('van-cell', {
      attrs: {
        "value-class": "cell-amount",
        "title": "收款金额",
        "value": '￥' + unpaidReceive.receive_amount
      }
    }), _c('van-cell', {
      attrs: {
        "is-link": "",
        "title": "查看明细",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(unpaidReceive);
        }
      }
    })], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }